import { useContext, useState } from "react";

import { Button, Col, Form, Input, Row, Select } from "antd";

import EntidadContext from "../../store/entidad-context";
import { required } from "./../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import classes from "./entidades-smtp.module.scss";

import icon from "./../../assets/images/communications.png";

const EntidadesSmtp = (props) => {
  const context = useContext(EntidadContext);
  const disabled = !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);

  const onClickSave = async (values) => {
    setLoading(true);

    const response = await http.patch(`entidades/${context.id}/smtp`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Servidor de Emails actualizado",
        "Los datos del servidor de emails se guardaron correctamente."
      );
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Servidor de Emails</div>
          <div className={classes.description}>
            Configurá los datos de tu servidor de envio de correos electrónicos.
          </div>
        </div>
      </div>
      <Form layout="vertical" form={context.formSmtp} onFinish={onClickSave}>
        <Row gutter={16}>
          <Col xs={16}>
            <Form.Item label="Servidor" name="smtpServidor" rules={[required]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Puerto" name="smtpPuerto" rules={[required]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>

          <Col xs={16}>
            <Form.Item
              label="Direccion"
              name="smtpDireccion"
              rules={[required]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item label="Ssl" name="smtpSsl" rules={[required]}>
              <Select disabled={disabled}>
                <Select.Option value="1">Activado</Select.Option>
                <Select.Option value="0">No activado</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item label="Usuario" name="smtpUsuario" rules={[required]}>
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label="Contraseña"
              name="smtpPassword"
              rules={[required]}
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        {!disabled && (
          <div className="actions">
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Actualizar"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EntidadesSmtp;

import { useContext, useState } from "react";
import { Button, Form, Input } from "antd";

import EntidadContext from "../../store/entidad-context";
import { required } from "./../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

import classes from "./entidades-matriz.module.scss";

import icon from "./../../assets/images/meter.png";

const EntidadesMatriz = () => {
  const context = useContext(EntidadContext);

  const disabled = !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);

  const fields = [
    {
      title: "1. Documento - Lista Blanca",
      description: "El número de documento esta registrado en la lista blanca.",
      name: "puntajeListaBlancaDocumento",
    },
    {
      title: "2. Documento - Lista Negra",
      description: "El número de documento esta registrado en la lista negra.",
      name: "puntajeListaNegraDocumento",
    },
    {
      title: "3. Amazon - Comparación Facial",
      description:
        "La comparación facial entre la foto del documento y la selfie cargada no supera el umbral mínimo",
      name: "puntajeComparacionFacial",
    },
    {
      title: "4. Worldsys - Terrorista",
      description:
        "La persona se encuentra registrada en la base de datos de Worldsys como 'Terrorista'.",
      name: "puntajeTerrorista",
    },
    {
      title: "5. Worldsys - Persona Expuesta Politicamente",
      description:
        "La persona se encuentra registrada en la base de datos de Worldsys como 'Persona Expuesta Politicamente'.",
      name: "puntajePep",
    },
    {
      title: "6. UIF - Sujeto Obligado",
      description:
        "La persona se encuentra registrada en la base de datos de Worldsys como 'Sujeto Obligado'.",
      name: "puntajeUif",
    },
    {
      title: "7. AFIP - Sin Actividad",
      description:
        "La persona no posee ninguna actividad en el Padrón A5 de AFIP.",
      name: "puntajeAcitivdad",
    },
    {
      title: "8. Email - Lista Negra",
      description: "El correo electrónico esta registrado en la lista negra.",
      name: "puntajeListaNegraEmail",
    },
    {
      title: "9. Teléfono - Lista Negra",
      description: "El teléfono esta registrado en la lista negra.",
      name: "puntajeListaNegraTelefono",
    },
    {
      title: "10. SEON - Scoring de Email insuficiente",
      description:
        "El correo electrónico no supera el límite esperado de scoring realizado por SEON.",
      name: "puntajeScoringEmail",
    },
    {
      title: "11. SEON - Scoring de Teléfono insuficiente",
      description:
        "El teléfono no supera el límite esperado de scoring realizado por SEON.",
      name: "puntajeScoringTelefono",
    },
    {
      title: "12. MORFOLOGIA - DNI no encontrado",
      description:
        "No se encontro DNI en las imagenes.",
      name: "puntajeMorfologia",
    }
  ];

  const onClickSave = async (values) => {
    setLoading(true);

    const response = await http.patch(`entidades/${context.id}/matriz`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Matriz de riesgo actualizada",
        "Los puntajes de la matriz de riesgo se guardaron correctamente."
      );
    }
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="matriz" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Matriz de Riesgo</div>
          <div className={classes.description}>
            Configurá que puntaje de riesgo asignarle a las diferentes
            validaciones del proceso.
          </div>
        </div>
      </div>
      <Form
        layout="vertical"
        form={context.formMatriz}
        className={classes.form}
        onFinish={onClickSave}
      >
        <div className={`${classes.item} ${classes.yellow}`}>
          <div className={classes.data}>
            <div className={classes.title}>Puntaje Total para Revisar</div>
            <div className={classes.description}>
              Las solicitudes que excedan este puntaje de riesgo, deberán ser
              revisadas para poder continuar.
            </div>
          </div>
          <div className={classes.input}>
            <Form.Item name="puntajePendiente" rules={[required]}>
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </div>
        </div>

        <div className={`${classes.item} ${classes.red}`}>
          <div className={classes.data}>
            <div className={classes.title}>Puntaje Total para Rechazar</div>
            <div className={classes.description}>
              Las solicitudes que excedan este puntaje de riesgo, se rechazaran
              automáticamente.
            </div>
          </div>
          <div className={classes.input}>
            <Form.Item name="puntajeRechazar" rules={[required]}>
              <Input type="number" disabled={disabled} />
            </Form.Item>
          </div>
        </div>

        {fields.map((field, index) => (
          <div key={index} className={classes.item}>
            <div className={classes.data}>
              <div className={classes.title}>{field.title}</div>
              <div className={classes.description}>{field.description}</div>
            </div>
            <div className={classes.input}>
              <Form.Item name={field.name} rules={[required]}>
                <Input type="number" disabled={disabled} />
              </Form.Item>
            </div>
          </div>
        ))}

        {!disabled && (
          <div className="actions">
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Actualizar"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EntidadesMatriz;

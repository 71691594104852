import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiInbox, FiPrinter, FiSearch } from "react-icons/fi";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import { TableContextProvider } from "./../../store/table-context";

import SolicitudesEstado from "../../components/solicitudes/solicitudes-estado";
import http from "./../../services/http";

const SolicitudesListPage = () => {
  let navigate = useNavigate();

  const title = "Solicitudes";
  const icon = <FiInbox />;
  const breadcrumb = [{ title: "Solicitudes", url: "/solicitudes" }];

  const [loadingExport, setLoadingExport] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [filters, setFilters] = useState([
    {
      type: "date",
      label: "Fecha Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Fecha Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "input",
      label: "Nombres",
      name: "nombres",
    },
    {
      type: "input",
      label: "Apellidos",
      name: "apellidos",
    },
    {
      type: "input",
      label: "Documento",
      name: "documento",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "1", text: "Pendiente" },
        { value: "2", text: "Aprobada" },
        { value: "3", text: "Rechazada" },
        { value: "4", text: "Validación Manual" },
        { value: "5", text: "Pendiente credenciales" },
        { value: "6", text: "Error en alta" },
      ],
      onChange: (value, form) => {
        form.setFieldsValue({ motivoRechazo: '' });
        let nFilters = [...filters];
        nFilters[6].hidden = value !== "3";
        setFilters(nFilters);
      }
    },
    {
      type: "select",
      label: "Motivo de Rechazo",
      name: "motivoRechazo",
      hidden: true,
      values: [
        { value: "1", text: "Documento No Encontrado" },
        { value: "2", text: "Pdf No Encontrado" },
        { value: "3", text: "Intentos Excedidos" },
        { value: "4", text: "Persona No Encontrada" },
        { value: "5", text: "Persona Fallecida" },
        { value: "6", text: "Ejemplar No Valido" },
        { value: "7", text: "Prueba Vida Intentos Excedidos" },
        { value: "8", text: "Rechazo Matriz De Riesgo" },
        { value: "9", text: "Solicitud Previa Aprobada" },
      ],
    }
  ]);

  const columns = [
    { title: "Fecha", property: "fecha", sortable: true },
    { title: "Hora", property: "hora", sortable: false },
    { title: "Nombres", property: "nombres", sortable: true },
    { title: "Apellidos", property: "apellidos", sortable: true },
    { title: "Documento", property: "documento", sortable: true },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <SolicitudesEstado estado={item.estado} title={item.motivoRechazo} />,
    },
  ];

  const onClickExportarExcel = async (e) => {
    setLoadingExport(true)
    let url = `exportsolicitudes?orderBy=fecha&orderDirection=descending`;

    for (const property in e.filters) {
      url += `&${property}=${e.filters[property] ?? ""}`;
    }


    const response = await http.get(url);

    if (response.data === '') {
      return false;
    }
    const base = atob(response.data.file);

    const element = document.createElement("a");
    const file = new Blob([Uint8Array.from(base, (c) => c.charCodeAt(0))]);
    element.href = URL.createObjectURL(file);
    element.download = "solicitudes.xlsx";
    document.body.appendChild(element);
    element.click();
    setLoadingExport(false)
  };
  const buttons = [
    {
      title: "Exportar a Excel",
      text: <FiPrinter />,
      type: "primary",
      onClick: onClickExportarExcel,
      loading: loadingExport,
      visible: true,
    },
  ];
  const menu = (item) => (
    <Menu>
      <Menu.Item key="1" icon={<FiSearch />} onClick={() => onClickView(item)}>
        Ver
      </Menu.Item>
    </Menu>
  );

  const onClickView = (item) => {
    navigate(`/solicitudes/${item.id}`);
  };




  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="table-solicitudes"
          columns={columns}
          menu={menu}
          url="/solicitudes"
          orderBy="fecha"
          orderDirection="descending"
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default SolicitudesListPage;

import { createContext, useState } from "react";

const SolicitudContext = createContext({
  solicitud: {},
  reload: {},
  updateSolicitud: () => {},
  reloader: () => {},
});

export function SolicitudContextProvider(props) {
  // State
  const [solicitud, setSolicitud] = useState({
    archivos: [],
    json: [],
  });
  const [reload, setReload] = useState({});

  // Methods
  const updateSolicitud = (item) => {
    setSolicitud(item);
  };
  const reloader = () => {
    setReload(!reload);
  };

  const context = {
    solicitud: solicitud,
    reload: reload,
    updateSolicitud: updateSolicitud,
    reloader: reloader
  };

  return (
    <SolicitudContext.Provider value={context}>
      {props.children}
    </SolicitudContext.Provider>
  );
}

export default SolicitudContext;

import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { FiArrowRight, FiBriefcase } from "react-icons/fi";
import jwt from "jwt-decode";

import classes from "./entidades-form.module.scss";

import logo from "./../../assets/images/logo.png";
import http from "./../../services/http";

const EntidadesForm = () => {
  const [entidades, setEntidades] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    async function getData() {
      const serialized = localStorage.getItem("token");

      localStorage.clear();
      localStorage.setItem("token", serialized);

      const token = jwt(serialized);

      let response = await http.get(`usuarios/${token.id}/entidades`);
      if (response) {
        const data = response.data;
        setEntidades(data);
      }
    }

    getData();
  }, []);

  const onClickEntidad = (event, entidad) => {
    if (event.detail === 2) {
      setSelected(entidad);
      onClickContinue();
      return;
    }

    if (event.detail === 1) {
      setSelected(entidad);
    }
  };

  const onClickContinue = () => {
    if (!selected) {
      return;
    }

    localStorage.setItem("entidad", JSON.stringify(selected));
    window.location.replace("/");
  };

  return (
    <div className={classes.form}>
      <img className={classes.logo} src={logo} alt="logo" />

      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>Seleccioná una Entidad</div>
          <div className={classes.description}>
            Podes cambiar de entidad en cualquier momento desde el menu superior
            derecho
          </div>
        </div>

        <div className={classes.list}>
          <Row gutter={[20, 20]} justify="center">
            {entidades.map((entidad, index) => (
              <Col xs={24} lg={8} key={index}>
                <div
                  className={`${classes.item} ${
                    selected === entidad ? classes.selected : ""
                  }`}
                  onClick={(e) => onClickEntidad(e, entidad)}
                >
                  <div className={classes.icon}>
                    <FiBriefcase />
                  </div>
                  <div className={classes.title}>{entidad.nombre}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <div
          className={`${classes.continue} ${!selected ? classes.disabled : ""}`}
          onClick={onClickContinue}
        >
          <FiArrowRight />
        </div>
      </div>
    </div>
  );
};

export default EntidadesForm;

import {
  FiBriefcase,
  FiInbox,
  FiMapPin,
  FiMonitor,
  FiSettings,
  FiShield,
  FiThumbsDown,
  FiThumbsUp,
  FiUser,
} from "react-icons/fi";

import { actions, hasPermission } from "./../../services/security";

import InicioSection from "../../components/inicio/inicio-section";

const InicioPage = () => {
  const sections = [
    {
      title: "Inicio",
      links: [
        {
          title: "Solicitudes",
          url: "/solicitudes",
          icon: <FiInbox />,
          visible: hasPermission(actions.SolicitudesVer),
        },
        {
          title: "Solicitudes PJ",
          url: "/solicitudesPJ",
          icon: <FiInbox />,
          visible: hasPermission(actions.SolicitudesPJVer),
        },
        {
          title: "Comercios",
          url: "/comercios",
          icon: <FiMapPin />,
          visible: hasPermission(actions.ComerciosVer),
        },
      ],
    },
    {
      title: "Configuración",
      links: [
        {
          title: "Entidades",
          url: "/entidades",
          icon: <FiBriefcase />,
          visible: hasPermission(actions.EntidadesVer),
        },
        {
          title: "Lista Blanca",
          url: "/lista-blanca",
          icon: <FiThumbsUp />,
          visible: hasPermission(actions.ListaBlancaVer),
        },
        {
          title: "Lista Negra",
          url: "/lista-negra",
          icon: <FiThumbsDown />,
          visible: hasPermission(actions.ListaNegraVer),
        },
        {
          title: "Parámetros",
          url: "/parametros",
          icon: <FiSettings />,
          visible: hasPermission(actions.ParametrosVer),
        },
      ],
    },
    {
      title: "Seguridad",
      links: [
        {
          title: "Perfiles",
          url: "/perfiles",
          icon: <FiShield />,
          visible: hasPermission(actions.PerfilesVer),
        },
        {
          title: "Usuarios",
          url: "/usuarios",
          icon: <FiUser />,
          visible: hasPermission(actions.UsuariosVer),
        },
        {
          title: "Auditoría",
          url: "/auditorias",
          icon: <FiMonitor />,
          visible: hasPermission(actions.AuditoriasVer),
        },
      ],
    },
  ];

  return (
    <div>
      {sections.map(
        (section, index) =>
          section.links.filter((x) => x.visible).length > 0 && (
            <InicioSection key={index} item={section} />
          )
      )}
    </div>
  );
};

export default InicioPage;

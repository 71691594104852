/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalError, modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const ComerciosForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = props;
  const disablesInput = id !== "nuevo" ? true : (!hasPermission(actions.ComerciosCrear));


  const [loading, setLoading] = useState(false);
  const [actividadAfip, setActividadAfip] = useState();

  const [provincias, setProvincias] = useState([]);
  const [categoriasIva, setCategoriasIva] = useState([]);
  const [sicore, setSicore] = useState([]);
  const [rubrosRg, setRubrosRg] = useState([]);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("comercios/categoriaiva");
      if (response) {
        const data = response.data;
        setCategoriasIva(data);
      }

      response = await http.get("comercios/provincias");
      if (response) {
        const data = response.data;
        setProvincias(data);
      }

      response = await http.get("comercios/rubrorg");
      if (response) {
        const data = response.data;
        setRubrosRg(data);
      }

      response = await http.get("comercios/sicore");
      if (response) {
        const data = response.data;
        setSicore(data);
      }

      if (id === "nuevo") {
        form.setFieldsValue({
          fecha: moment(new Date()).format("DD/MM/YYYY"),
          hora: moment(new Date()).format("HH:mm"),
          tipoCuenta: "1",
        });
        return;
      }

      response = await http.get(`comercios/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (!actividadAfip) {
        return;
      }

      const response = await http.get(
        `comercios/mcc?codActividad=${actividadAfip}`
      );
      if (response) {
        const data = response.data;
        form.setFieldsValue({ mcc: data.valor });
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [actividadAfip]);

  const onClickBack = () => {
    navigate("/comercios");
  };

  const onClickSave = async (values) => {
    setLoading(true);
    const response = await http.post("comercios", values).catch(async (error) => {
      console.log("Response:", error.response);

      await modalError(
        "Error al crear comercio",
        error.response.data.errores[0].detalle,
      );
    });

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Comercio guardado",
        id === "nuevo"
          ? "El comercio fue creado exitosamente"
          : "El comercio fue modificado exitosamente"
      );
      navigate("/comercios");
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={12}>
          <Form.Item label="Fecha" name="fecha">
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Hora" name="hora">
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label="Tipo de Persona"
            name="tipoPersona"
            rules={[required]}
          >
            <Select allowClear disabled={disablesInput}>
              <Select.Option value="F">Física</Select.Option>
              <Select.Option value="J">Jurídica</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Cuit" name="cuit" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Nombre de Fantasía"
            name="descripcion"
            rules={[required]}
          >
            <Input maxLength={40} disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Email" name="email" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Teléfono" name="telefono" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Calle" name="calle" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item label="Piso" name="piso" >
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item
            label="Departamento"
            name="departamento"
          >
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        <Col xs={4}>
          <Form.Item
            label="Código Postal"
            name="codigoPostal"
            rules={[required]}
          >
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item label="Provincia" name="codigoProvincia" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disablesInput}
            >
              {provincias.map((option, index) => (
                <Select.Option key={index} value={option.codigo}>
                  {option.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col xs={12}>
          <Form.Item label="Localidad" name="codigoLocalidad">
            <Input disabled={disabled} />
          </Form.Item>
        </Col> */}

        <Col xs={12}>
          <Form.Item
            label="Categoría IVA"
            name="categoriaIva"
            rules={[required]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disablesInput}
            >
              {categoriasIva.map((option, index) => (
                <Select.Option key={index} value={option.key}>
                  {option.valor}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="Sicore" name="sicore" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disablesInput}
            >
              {sicore.map((option, index) => (
                <Select.Option key={index} value={option.key}>
                  {option.valor}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Rubro RG" name="rubroRg" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disablesInput}
            >
              {rubrosRg.map((option, index) => (
                <Select.Option key={index} value={option.key}>
                  {option.valor}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            disabled={disablesInput}
            label="Actividad AFIP"
            name="actividadEconomicaAfip"
            rules={[required]}
          >
            <Input
              disabled={disablesInput}
              onChange={(e) => setActividadAfip(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item label="MCC Rubro Visa" name="mcc" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Tipo de Cuenta" name="tipoCuenta">
            <Select allowClear disabled={disablesInput}>
              <Select.Option value="1">Caja de Ahorro</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="CBU" name="cbu" rules={[required]}>
            <Input disabled={disablesInput} />
          </Form.Item>
        </Col>
        {disablesInput ? (
          <><Col xs={12}>
            <Form.Item label="Codigo Caja" name="codigoCaja" rules={[required]}>
              <Input disabled={disablesInput} />
            </Form.Item>
          </Col>
            <Col xs={12}>
              <Form.Item label="Codigo Sucursal" name="codigoSucursal" rules={[required]}>
                <Input disabled={disablesInput} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Comercio QRId" name="comercioQRId" rules={[required]}>
                <Input disabled={disablesInput} />
              </Form.Item>
            </Col></>
        ) : ''}

      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disablesInput && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ComerciosForm;

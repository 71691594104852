import { useState, useContext, useRef } from "react";
import { Col, Form, Row, Button } from "antd";

import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";
import { modalSuccess } from "../../services/notifications";
import EntidadContext from "../../store/entidad-context";
import { required } from "./../../services/forms";

import icon from "./../../assets/images/painting.png";
import empty from "./../../assets/images/no-photo.png";
import { FiUpload } from "react-icons/fi";
import ColorPicker from "../ui/color-picker";

import classes from "./entidades-estilos.module.scss"

const EntidadesEstilos = (props) => {
  const context = useContext(EntidadContext);
  const disabled = !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);

  const onLogoClicked = () => {
    imgRef.current.click();
  }

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) return;

    let reader = new FileReader();
    reader.onload = async () => {
      const result = reader.result?.trim();
      context.updateLogo(result);
      context.formEstilos.setFieldsValue({logo: result});
    };

    reader.readAsDataURL(file);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response = await http.patch(`entidades/${context.id}/estilos`, values);

    setLoading(false);

    if(response)
      await modalSuccess(
        "Diseño actualizado",
        "Los valores para el diseño se guardaron correctamente."
      );
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="diseno" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Diseño</div>
          <div className={classes.description}>
            Personalizá el estilo del sitio a tu gusto.
          </div>
        </div>
      </div>
      <Form layout="vertical" form={context.formEstilos} onFinish={onClickSave}>
        <Row gutter={16} justify="center">
          <Col xs={24} lg={24}>
            <Form.Item
              label="Logo"
              name="logo"
              rules={[required]}
            >
              <div className={classes.logo}>
                <div className={`${classes.container} ${classes.transparent}`} onClick={onLogoClicked}>
                  <div className={`${classes.image} ${context.logo ? '' : classes.empty}`}>
                    <img src={context.logo ?? empty} alt="logo" />
                  </div>
                  <div className={classes.iconContainer}>
                    <FiUpload className={classes.icon}/>
                  </div>
                </div>
              </div>
              <input type="file" ref={imgRef} accept=".png" onChange={onChangeFile}/>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label="Color" name="color" rules={[required]}>
              <ColorPicker />
            </Form.Item>
          </Col>
        </Row>
        {!disabled && (
          <div className="actions">
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Actualizar"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EntidadesEstilos;

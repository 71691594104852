import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import moment from "moment";

import EntidadContext from "../../store/entidad-context";
import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const EntidadesClavesForm = (props) => {
  const [form] = Form.useForm();
  const { item, onSave, onClose } = props;
  const context = useContext(EntidadContext);
  const disabled = !hasPermission(actions.EntidadesEditar);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item]);

  const onClickSave = async (values) => {
    setLoading(true);

    values.id = item.id;
    values.fecha = moment(new Date()).format("DD/MM/YYYY");

    const response = await http.patch(`entidades/${context.id}/claves`, values);
    if (response) {
      let nItem = { ...item };
      nItem.asunto = values.asunto;
      nItem.valor = values.valor;
      nItem.fecha = values.fecha;
      onSave(nItem);

      await modalSuccess(
        "Clave actualizada",
        "La clave se guardo correctamente."
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      visible={item}
      title={disabled ? "Ver clave" : "Editar clave"}
      footer={null}
      onCancel={onClose}
    >
      <Form layout="vertical" form={form} onFinish={onClickSave}>
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item label="Producto" name="producto">
              <Input disabled />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label="Clave" name="valor">
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <div className="actions">
          <Button type="text" onClick={onClose}>
            Cerrar
          </Button>
          {!disabled && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && "Actualizar"}
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default EntidadesClavesForm;

import { useParams } from "react-router-dom";
import { FiShield } from "react-icons/fi";

import { PerfilContextProvider } from "./../../store/perfil-context";
import { actions, hasPermission } from "../../services/security";

import Card from "./../../components/ui/card";
import PerfilesForm from "../../components/perfiles/perfiles-form";
import Header from "./../../components/ui/header";

const PerfilesDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Crear"
      : hasPermission(actions.UsuariosEditar)
      ? "Editar"
      : "Ver";

  const title = `${action} Perfil`;
  const icon = <FiShield />;
  const breadcrumb = [
    { title: "Perfiles", url: "/perfiles" },
    { title: action, url: `/perfiles/${id}` },
  ];

  return (
    <PerfilContextProvider>
      <Card>
        <Header title={title} icon={icon} breadcrumb={breadcrumb} />
        <PerfilesForm id={id} />
      </Card>
    </PerfilContextProvider>
  );
};

export default PerfilesDetailPage;

import { Tag, Tooltip } from "antd";

const SolicitudesPJEstado = (props) => {
  const { className, estado, title } = props;

  switch (estado) {
    case 1:
      return (

        <Tag className={className} color="gold" >
          Pendiente
        </Tag>

      );

    case 2:
      return (

        <Tag className={className} color="green">
          Aprobada
        </Tag>

      );

    case 3:
      return (
        <Tooltip title={title}>
          <Tag className={className} color="red">
            Rechazada
          </Tag>
        </Tooltip>
      );

    case 4:
      return (
        <Tooltip title={title}>
          <Tag className={className} color="orange">
            Verificación Manual
          </Tag>
        </Tooltip>
      );

    case 5:
      return (
        <Tooltip title={title}>
          <Tag className={className} color="lime">
            Pendiente credenciales
          </Tag>
        </Tooltip>
      );

    case 6:
      return (
        <Tooltip title={title}>
          <Tag className={className} color="volcano">
            Error en alta
          </Tag>
        </Tooltip>
      );
    default:
      return "-";
  }
};

export default SolicitudesPJEstado;

import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Switch, Modal, Row, Col } from "antd";

import EntidadContext from "../../store/entidad-context";

import { required } from "./../../services/forms";

const EntidadesProductosForm = (props) => {
  const [form] = Form.useForm();
  const { item, onSave, onClose } = props;
  const context = useContext(EntidadContext);

  const [checkedPrincipal, setCheckedPrincipal] = useState(false);
  const [checkedCC, setCheckedCC] = useState(false);

  useEffect(() => {
    console.log(item)
    if (item) {
      form.setFieldsValue(item);
      setCheckedPrincipal(item.esPrincipal);
      setCheckedCC(item.esCuentaCorriente);
    }
  }, [item]);

  const onClickSave = async (values) => {
    let nProductos = [...context.productos];

    if (checkedPrincipal) {
      const i = nProductos.findIndex(p => p.esPrincipal);
      if (i >= 0) nProductos[i].esPrincipal = false;
    }


    let index = nProductos.findIndex(p => (item.id !== 0 && p.id === item.id) || (item.id === 0 && p.codProducto === item.codProducto));
    if (index >= 0 && item?.codProducto !== '') {
      nProductos[index].codProducto = values.codProducto;
      nProductos[index].esPrincipal = checkedPrincipal;
      nProductos[index].esCuentaCorriente = checkedCC;
    }
    else {
      let producto = {
        id: 0,
        codProducto: values.codProducto,
        esPrincipal: checkedPrincipal,
        esCuentaCorriente: checkedCC
      }
      nProductos.push(producto);
    }

    form.resetFields();
    onSave(nProductos);
  };

  const onChangeEsPrincipal = (value) => {
    setCheckedPrincipal(value);
  }

  const onChangeEsCC = (value) => {
    setCheckedCC(value);
  }

  const onCancel = () => {
    form.resetFields();
    onClose();
  }

  return (
    <Modal
      centered
      visible={item}
      title={item?.codProducto ? 'Editar producto' : 'Crear producto'}
      footer={null}
      onCancel={onCancel}
    >
      <Form form={form}
        layout="vertical"
        onFinish={onClickSave}
      >
        <Row gutter={20}>
          <Col xs={24}>
            <Form.Item label="Producto" name="codProducto" rules={[required]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="centered"
              name="esPrincipal"
              label="Es principal"
            >
              <Switch checked={checkedPrincipal} onChange={onChangeEsPrincipal} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item className="centered"
              name="esCuentaCorriente"
              label="Es cuenta corriente"
            >
              <Switch checked={checkedCC} onChange={onChangeEsCC} />
            </Form.Item>
          </Col>
        </Row>
        <div className="actions">
          <Button type="text" onClick={onCancel}>
            Cerrar
          </Button>
          <Button type="primary" htmlType="submit">
            {item?.codProducto ? 'Actualizar' : 'Crear'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EntidadesProductosForm;

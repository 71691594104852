import { useParams } from "react-router-dom";
import { FiThumbsUp } from "react-icons/fi";

import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import ListaBlancaForm from "../../components/lista-blanca/lista-blanca-form";
import Header from "../../components/ui/header";

const ListaBlancaDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Agregar a "
      : hasPermission(actions.ListaBlancaEditar)
      ? "Editar"
      : "Ver";

  const title = `${action} Lista Blanca`;
  const icon = <FiThumbsUp />;
  const breadcrumb = [
    { title: "Lista Blanca", url: "/lista-blanca" },
    { title: action, url: `/lista-blanca/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <ListaBlancaForm id={id} />
    </Card>
  );
};

export default ListaBlancaDetailPage;

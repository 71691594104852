import { createContext, useState } from "react";

const TableContext = createContext({
  filtersVisible: false,
  filters: {},

  updateFiltersVisible: (showFilters) => {},
  updateFilters: (filters) => {},
});

export function TableContextProvider(props) {
  // State
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState({});

  // Methods
  const updateFiltersVisible = (visible) => {
    setFiltersVisible(visible);
  };

  const updateFilters = (filters) => {
    setFilters(filters);
  };

  const context = {
    filtersVisible: filtersVisible,
    filters: filters,

    updateFiltersVisible: updateFiltersVisible,
    updateFilters: updateFilters,
  };

  return (
    <TableContext.Provider value={context}>
      {props.children}
    </TableContext.Provider>
  );
}

export default TableContext;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, hasPermission } from "../../services/security";

const ListaNegraForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = props;

  const disabled =
    id === "nuevo"
      ? !hasPermission(actions.ListaNegraCrear)
      : !hasPermission(actions.ListaNegraEditar);

  const [loading, setLoading] = useState(false);

  const [tipos, setTipos] = useState([]);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("lista-negra/tipos");
      if (response) {
        const data = response.data;
        setTipos(data);
      }

      if (id === "nuevo") {
        return;
      }

      response = await http.get(`lista-negra/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate("/lista-negra");
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("lista-negra", values)
        : await http.put(`lista-negra/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Registro guardado",
        id === "nuevo"
          ? "El registro en lista negra fue creado exitosamente"
          : "El registro en lista negra fue modificado exitosamente"
      );
      navigate("/lista-negra");
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Tipo" name="tipo">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disabled}
            >
              {tipos.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Valor" name="valor" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Motivo" name="motivo" className="textarea">
            <Input.TextArea showCount maxLength={500} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ListaNegraForm;

import { createContext, useState } from "react";

const SolicitudPJContext = createContext({
  solicitudPJ: {},
  updateSolicitudPJ: () => {},
});

export function SolicitudPJContextProvider(props) {
  //state
  const [solicitudPJ, setSolicitudPJ] = useState({
    archivos: [],
    json: [],
  });

  // Methods
  const updateSolicitudPJ = (item) => {
    setSolicitudPJ(item);
  };

  const context = {
    solicitudPJ: solicitudPJ,
    updateSolicitudPJ: updateSolicitudPJ,
  };

  return (
    <SolicitudPJContext.Provider value={context}>
      {props.children}
    </SolicitudPJContext.Provider>
  );
}

export default SolicitudPJContext;

import { useParams } from "react-router-dom";
import { FiMapPin } from "react-icons/fi";

import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import ComerciosForm from "../../components/comercios/comercios-form";
import Header from "../../components/ui/header";

const ComerciosDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Agregar "
      : hasPermission(actions.ListaNegraEditar)
      ? "Editar"
      : "Ver";

  const title = `${action} Comercio`;
  const icon = <FiMapPin />;
  const breadcrumb = [
    { title: "Comercios", url: "/comercios" },
    { title: action, url: `/comercios/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <ComerciosForm id={id} />
    </Card>
  );
};

export default ComerciosDetailPage;

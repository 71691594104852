import { useContext, useState } from "react";
import { Dropdown, Menu, Tag } from "antd";
import { FiMoreVertical, FiSearch, FiEdit } from "react-icons/fi";


import EntidadContext from "../../store/entidad-context";
import { actions, hasPermission } from "../../services/security";

import EntidadesNotificacionesForm from "./entidades-notificaciones-form";

import classes from "./entidades-notificaciones.module.scss";

import icon from "./../../assets/images/email.png";
import EntidadesNotificacionesPreview from "./entidades-notificaciones-preview";

const EntidadesNotificaciones = () => {
  const context = useContext(EntidadContext);

  const [current, setCurrent] = useState();
  const [preview, setPreview] = useState();

  const onClickPreview = (item) => {
    setPreview(item);
  };

  const onClickEdit = (item) => {
    setCurrent(item);
  };

  const menu = (item) => (
    <Menu>
      <Menu.Item
        key="1"
        icon={<FiSearch />}
        onClick={() => onClickPreview(item)}
      >
        Ver
      </Menu.Item>
      {hasPermission(actions.EntidadesEditar) && (
        <Menu.Item key="2" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
    </Menu>
  );

  const onSave = (item) => {
    let nNotificaciones = [...context.notificaciones];
    const index = nNotificaciones.indexOf(nNotificaciones.find((x) => x.id === item.id));
    nNotificaciones[index] = item;
    context.updateNotificaciones(nNotificaciones);
    setCurrent(undefined);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Notificaciones</div>
          <div className={classes.description}>
            Configurá las plantillas de las notificaciones que serán enviadas.
          </div>
        </div>
      </div>
      <div className={classes.list}>
        {context.notificaciones.map((notificacion, index) => (
          <div key={index} className={classes.item}>
            <Dropdown
              overlay={() => menu(notificacion)}
              placement="bottomLeft"
              arrow
              trigger="click"
            >
              <div className={classes.menu}>
                <FiMoreVertical />
              </div>
            </Dropdown>
            <div className={classes.content}>
              <div className={classes.data}>
                <div className={classes.title}>{notificacion.tipo}</div>
                <div className={classes.description}>
                  Última actualización: {notificacion.fecha}
                </div>
              </div>
              <div className={classes.status}>
                {!notificacion.habilitado && <Tag color="red">Deshabilitado</Tag>}
              </div>
            </div>
          </div>
        ))}
      </div>

      <EntidadesNotificacionesPreview
        item={preview}
        onClose={() => setPreview(undefined)}
      />

      <EntidadesNotificacionesForm
        item={current}
        onSave={onSave}
        onClose={() => setCurrent(undefined)}
      />
    </div>
  );
};

export default EntidadesNotificaciones;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  FiBriefcase,
  FiEdit,
  FiPlus,
  FiSearch,
  FiTrash2,
} from "react-icons/fi";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";

import { TableContextProvider } from "../../store/table-context";
import { confirm, modalSuccess } from "../../services/notifications";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

const EntidadesListPage = () => {
  let navigate = useNavigate();

  const title = "Entidades";
  const icon = <FiBriefcase />;
  const breadcrumb = [{ title: "Entidades", url: "/entidades" }];

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      type: "input",
      label: "Nombre",
      name: "nombre",
    },
  ]);

  const columns = [{ title: "Nombre", property: "nombre", sortable: true }];

  const menu = (item) => (
    <Menu>
      {!hasPermission(actions.EntidadesEditar) && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickEdit(item)}
        >
          Ver
        </Menu.Item>
      )}
      {hasPermission(actions.EntidadesEditar) && (
        <Menu.Item key="1" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
      {hasPermission(actions.EntidadesEliminar) && (
        <Menu.Item
          key="2"
          icon={<FiTrash2 />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/entidades/nuevo");
  };

  const onClickEdit = (item) => {
    navigate(`/entidades/${item.id}`);
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      "Eliminar entidad",
      `¿Esta seguro que desea eliminar la entidad ${item.nombre}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`entidades/${item.id}`);
    if (response) {
      await modalSuccess(
        "Entidad eliminada",
        "La entidad fue eliminada exitosamente"
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Entidad",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: hasPermission(actions.EntidadesCrear),
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="table-entidades"
          columns={columns}
          menu={menu}
          url="/entidades"
          orderBy="nombre"
          orderDirection="ascending"
          filterUsuario
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default EntidadesListPage;

import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import { FiInbox } from "react-icons/fi";

import { SolicitudPJContextProvider } from "../../store/solicitudPJ-context";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import SolicitudesPJForm from "../../components/solicitudesPJ/solicitudesPJ-form";

const SolicitudesPJDetailPage = () => {
  const { id } = useParams();

  const title = `Ver Solicitud Persona Juridica`;
  const icon = <FiInbox />;
  const breadcrumb = [
    { title: "Solicitudes Persona Juridica", url: "/solicitudesPJ" },
    { title: "Ver", url: `/solicitudesPJ/${id}` },
  ];

  return (
    <SolicitudPJContextProvider>
      <Row gutter={[20]}>
        <Col xs={24} lg={24}>
          <Card>
            <Header title={title} icon={icon} breadcrumb={breadcrumb} />
            <SolicitudesPJForm id={id} />
          </Card>
        </Col>
      </Row>
    </SolicitudPJContextProvider>
  );
};

export default SolicitudesPJDetailPage;

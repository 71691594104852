import { useState, useContext } from "react";
import { Button, Menu, Dropdown, Row, Col } from "antd";
import { FiTrash2, FiMoreVertical, FiEdit } from "react-icons/fi";

import EntidadContext from "../../store/entidad-context.js"

import classes from "./entidades-productos.module.scss"

import http from "../../services/http"
import { modalSuccess } from "../../services/notifications";

import EntidadesProductosForm from "./entidades-productos-form";
import product from "./../../assets/images/product.png";
import check from "./../../assets/images/check.png";
import search from "./../../assets/images/search.png";

const EntidadesProductos = () => {

    const context = useContext(EntidadContext);
    const [current, setCurrent] = useState();

    const onAddProduct = () => {
        setCurrent({
            id: 0,
            codProducto: '',
            esPrincipal: context.productos.length === 0,
            esCuentaCorriente: false,
            index: -1
        });
    }

    const onSave = async (nProductos) => {
        context.updateProductos(nProductos);
        setCurrent(undefined);

        let data = { productos: nProductos }

        const response = await http.patch(`entidades/${context.id}/productos`, data);
        if (!response.error) {
            await modalSuccess(
                "Producto actualizado",
                "El producto se ha guardado correctamente."
            );
        }
    };

    const onClickEdit = (item, index) => {
        setCurrent({ ...item, index });
    }

    const onClickDelete = (index) => {
        let productosTemp = [...context.productos];
        productosTemp.splice(index, 1);

        context.updateProductos(productosTemp);
        onSave(productosTemp);
    };

    const menu = (item, index) => (
        <Menu>
            <Menu.Item key="1" icon={<FiEdit />} onClick={() => onClickEdit(item, index)}>
                Editar
            </Menu.Item>
            <Menu.Item key="2" icon={<FiTrash2 />} onClick={() => onClickDelete(index)}>
                Eliminar
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <div className={classes.header}>
                <div className={classes.icon}>
                    <img src={product} />
                </div>
                <div className={classes.data}>
                    <div className={classes.title}>Productos</div>
                    <div className={classes.description}>Configurá tu lista de productos y seleccioná uno como el principal.</div>
                </div>
            </div>
            <div className={classes.list} >
                <Row className={classes.header_list}>
                    <Col className={classes.actions}>
                        <span></span>
                    </Col>
                    <Col className={`${classes.product} ${classes.column}`}>
                        <span>Producto</span>
                    </Col>
                    <Col className={`${classes.check} ${classes.column}`}>
                        <span>Es C.C.</span>
                    </Col>
                    <Col className={`${classes.check} ${classes.column}`}>
                        <span>Es principal</span>
                    </Col>
                </Row>
                {context.productos.map((producto, index) => (
                    <div key={index} className={classes.item}>
                        <div className={classes.content} >
                            <Dropdown
                                overlay={() => menu(producto, index)}
                                placement="bottomLeft"
                                arrow
                                trigger="click"
                            >
                                <div className={classes.menu}>
                                    <FiMoreVertical />
                                </div>
                            </Dropdown>
                            <div>
                                <span>{producto.codProducto}</span>
                            </div>
                        </div>
                        <div className={classes.check}>
                            {producto.esCuentaCorriente && <img src={check} alt="esPrincipal" className={classes.success} />}
                        </div>
                        <div className={classes.check}>
                            {producto.esPrincipal && <img src={check} alt="esPrincipal" className={classes.success} />}
                        </div>
                    </div>
                ))}
                {context.productos.length === 0 &&
                    <div className={classes.empty}>
                        <img src={search} alt="Empty" />
                        <div className={classes.title}>Sin productos</div>
                        <div className={classes.description}>
                            Para añadir, haga clic en "agregar producto"
                        </div>
                    </div>
                }
            </div>

            <div className={classes.action}>
                <Button className={classes.button} type="primary" htmlType="submit" onClick={onAddProduct}>
                    Agregar producto
                </Button>
            </div>

            <EntidadesProductosForm
                item={current}
                onSave={onSave}
                onClose={() => setCurrent(undefined)}
            />
        </div>
    );
};

export default EntidadesProductos;

import { useParams } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

import { actions, hasPermission } from "../../services/security";

import Card from "../../components/ui/card";
import ParametrosForm from "../../components/parametros/parametros-form";
import Header from "../../components/ui/header";

const ParametrosDetailPage = () => {
  const { id } = useParams();

  const action =
    id === "nuevo"
      ? "Crear"
      : hasPermission(actions.ParametrosEditar)
      ? "Editar"
      : "Ver";

  const title = `${action} Parámetro`;
  const icon = <FiSettings />;
  const breadcrumb = [
    { title: "Parámetros", url: "/parametros" },
    { title: action, url: `/parametros/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <ParametrosForm id={id} />
    </Card>
  );
};

export default ParametrosDetailPage;

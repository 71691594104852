import React, { useContext, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";

import TableContext from "../../store/table-context";

const Filters = (props) => {
  const [form] = Form.useForm();
  const { fields } = props;

  const context = useContext(TableContext);

  const onClickCancel = () => {
    context.updateFiltersVisible(false);
  };

  const onClickSearch = (values) => {
    for (const key in values) {
      if (moment.isMoment(values[key])) {
        values[key] = values[key].format("DD/MM/YYYY");
      }
    }

    context.updateFilters(values);
    context.updateFiltersVisible(false);
  };

  const onClickClear = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (context.filtersVisible) {
      let filters = {... context.filters };
      
      for (const key in filters) {
        let date = moment(filters[key], 'DD/MM/YYYY');
        if (date.isValid()) {
          filters[key] = date;
        }
      }

      form.setFieldsValue(filters);
    }
  }, [context.filtersVisible]);

  return (
    <Modal
      visible={context.filtersVisible}
      title="Aplicar filtros"
      footer={null}
      onCancel={onClickCancel}
    >
      <Form layout="vertical" form={form} onFinish={onClickSearch}>
        <Row gutter={20}>
          {fields.map((item, index) => (
            <Col span={item.half ? 12 : 24} key={index}>
              {item.type === "input" && !item.hidden && (
                <Form.Item label={item.label} name={item.name}>
                  <Input />
                </Form.Item>
              )}
              {item.type === "date" && !item.hidden &&(
                <Form.Item label={item.label} name={item.name}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              )}
              {item.type === "select" && !item.hidden && (
                <Form.Item label={item.label} name={item.name}>
                  <Select allowClear showSearch optionFilterProp="children" onChange={(value) => item.onChange(value, form)} >
                    {item.values.map((option, index) => (
                      <Select.Option key={index} value={option.value}>
                        {option.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          ))}
        </Row>

        <div className="actions">
          <Button type="text" onClick={onClickClear}>
            Limpiar
          </Button>
          <Button type="primary" htmlType="submit">
            Buscar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Filters;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { actions, getEntidad, hasPermission } from "../../services/security";

const UsuariosForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = props;

  const disabled =
    id === "nuevo"
      ? !hasPermission(actions.UsuariosCrear)
      : !hasPermission(actions.UsuariosEditar);

  const [loading, setLoading] = useState(false);
  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("perfiles");
      if (response) {
        const data = response.data;
        setPerfiles(data.list);
      }

      if (id === "nuevo") {
        return;
      }

      response = await http.get(`usuarios/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate("/usuarios");
  };

  const onClickSave = async (values) => {
    const entidad = getEntidad();

    values.entidad = entidad.id;

    setLoading(true);

    const response =
      id === "nuevo"
        ? await http.post("usuarios", values)
        : await http.put(`usuarios/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Usuario guardado",
        id === "nuevo"
          ? "El usuario fue creado exitosamente"
          : "El usuario fue modificado exitosamente"
      );
      navigate("/usuarios");
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Email" name="email" rules={[required]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Perfiles" name="perfiles" rules={[required]}>
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={disabled}
            >
              {perfiles.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default UsuariosForm;

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import moment from "moment";

import SolicitudPJContext from "../../store/solicitudPJ-context";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

import SolicitudesPJEstado from "./solicitudesPJ-estado";
import SolicitudesPJEstadosForm from "./solicitudesPJ-estado-form";

import classes from "./solicitudesPJ-form.module.scss";

const SolicitudesPJForm = (props) => {
  const [form] = Form.useForm();
  const context = useContext(SolicitudPJContext);

  const { id } = props;
  const [estado, setEstado] = useState(-1);
  const [estadoTemp, setEstadoTemp] = useState();

  const disabled = !hasPermission(actions.SolicitudesPJVer);

  useEffect(() => {
    async function getForm() {
      if (id === "nuevo") {
        return;
      }

      const response = await http.get(`solicitudesPJ/${id}`);
      if (response) {
        const data = response.data;
        context.updateSolicitudPJ(data);
        setEstado(data.estado);
      }
    }
    getForm();
  }, [id]);

  const onClickChangeEstado = (estado) => {
    setEstadoTemp(estado);
  };

  const onSubmit = (values) => {
    let nSolicitud = { ...context.solicitudPJ };
    nSolicitud.historial.unshift({
      comentario: values.comentario,
      estado: estadoTemp,
      estadoAnterior: estado,
      fecha: moment().format("DD/MM/YYYY"),
      hora: moment().format("HH:mm"),
      usuario: values.usuario,
    });
    context.updateSolicitudPJ(nSolicitud);

    setEstado(estadoTemp);
    setEstadoTemp(undefined);
  };

  const onClickInform = () => {
    console.log("INFORMED")
  };

  return (
    <Form layout="vertical" form={form}>
      <SolicitudesPJEstado className={`${classes.estado} glow`} estado={estado} />

      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item className="readonly-label" label="Identificador">
            <div className="value">{context.solicitudPJ.id ?? "-"}</div>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Fecha">
            <div className="value">{context.solicitudPJ.fecha ?? "-"}</div>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Hora">
            <div className="value">{context.solicitudPJ.hora ?? "-"}</div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item
            className="readonly-label"
            label="Nombre Fantasia"
          >
            <div className="value">
              {context.solicitudPJ.nombreFantasia ?? "-"}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="CUIT">
            <div className="value">
              {context.solicitudPJ.cuit}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Email">
            <div className="value">
              {context.solicitudPJ.email}
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Teléfono">
            <div className="value">
              {context.solicitudPJ.telefono}
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Rubro">
            <div className="value">
              {context.solicitudPJ.rubro}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item className="readonly-label" label="Ip">
            <div className="value">{context.solicitudPJ.ip ?? "-"}</div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item className="readonly-label" label="Dispositivo">
            <div className="value">{context.solicitudPJ.dispositivo ?? "-"}</div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SolicitudesPJForm;

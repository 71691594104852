import { useContext, useState } from "react";
import { Dropdown, Menu } from "antd";
import { FiEdit, FiMoreVertical, FiSearch } from "react-icons/fi";

import EntidadContext from "../../store/entidad-context";
import { actions, hasPermission } from "../../services/security";

import classes from "./entidades-claves.module.scss";

import icon from "./../../assets/images/key.png";
import EntidadesClavesForm from "./entidades-claves-form";

const EntidadesClaves = () => {
  const context = useContext(EntidadContext);

  const [current, setCurrent] = useState();

  const onClickEdit = (item) => {
    setCurrent(item);
  };

  const menu = (item) => (
    <Menu>
      {!hasPermission(actions.EntidadesEditar) && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickEdit(item)}
        >
          Ver
        </Menu.Item>
      )}
      {hasPermission(actions.EntidadesEditar) && (
        <Menu.Item key="2" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
    </Menu>
  );

  const onSave = (item) => {
    let nClaves = [...context.claves];
    const index = nClaves.indexOf(nClaves.find((x) => x.id === item.id));
    nClaves[index] = item;
    context.updateClaves(nClaves);
    setCurrent(undefined);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.icon}>
          <img src={icon} alt="emails" />
        </div>
        <div className={classes.data}>
          <div className={classes.title}>Claves</div>
          <div className={classes.description}>
            Administrá tus claves para los diferentes servicios del Api Manager.
          </div>
        </div>
      </div>
      <div className={classes.list}>
        {context.claves.map((clave, index) => (
          <div key={index} className={classes.item}>
            <Dropdown
              overlay={() => menu(clave)}
              placement="bottomLeft"
              arrow
              trigger="click"
            >
              <div className={classes.menu}>
                <FiMoreVertical />
              </div>
            </Dropdown>
            <div className={classes.content}>
              <div className={classes.title}>{clave.producto}</div>
              <div className={classes.description}>
                Última actualización: {clave.fecha}
              </div>
            </div>
          </div>
        ))}
      </div>

      <EntidadesClavesForm
        item={current}
        onSave={onSave}
        onClose={() => setCurrent(undefined)}
      />
    </div>
  );
};

export default EntidadesClaves;

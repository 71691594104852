import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ToastContainer } from "react-toastify";

import { getToken } from "../services/security";

import Navbar from "./../components/ui/navbar";
import Sidebar from "./../components/ui/sidebar";

import classes from "./Layout.module.scss";

const LayoutPage = () => {
  useEffect(() => {
    const token = getToken();

    if (!token) {
      window.location.replace("/auth/login");
    }
  }, []);

  return (
    <div className={classes.layout}>
      <Sidebar />
      <SimpleBar style={{ maxHeight: "100vh", width: "calc(100% - 260px)" }}>
        <div className={classes.main}>
          <Navbar />
          <Outlet />
          <ToastContainer />
        </div>
      </SimpleBar>
    </div>
  );
};

export default LayoutPage;

import React from "react";
import { Modal } from "antd";
import parse from "html-react-parser";

import classes from "./entidades-notificaciones-preview.module.scss";

const EntidadesNotificacionesPreview = (props) => {
  const { item, onClose } = props;

  return (
    <Modal
      visible={item}
      width={1000}
      centered
      title="Ver notificación"
      footer={null}
      onCancel={onClose}
    >
      <div className={classes.viewer}>{item && parse(item.contenido)}</div>
    </Modal>
  );
};

export default EntidadesNotificacionesPreview;

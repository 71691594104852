import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiEdit, FiMapPin, FiPlus, FiSearch, FiTrash } from "react-icons/fi";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";

import { TableContextProvider } from "../../store/table-context";
import { confirm, modalSuccess } from "../../services/notifications";
import http from "../../services/http";
import { actions, hasPermission } from "../../services/security";

const ComerciosListPage = () => {
  let navigate = useNavigate();

  const title = "Comercios";
  const icon = <FiMapPin />;
  const breadcrumb = [{ title: "Comercios", url: "/comercios" }];

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      type: "date",
      label: "Fecha Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Fecha Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "input",
      label: "Nombre de Fantasía",
      name: "nombre",
    },
    {
      type: "input",
      label: "CUIT",
      name: "cuit",
    },
  ]);

  const columns = [
    { title: "Fecha", property: "fecha", sortable: true },
    { title: "Hora", property: "hora", sortable: true },
    { title: "CUIT", property: "cuit", sortable: true },
    { title: "Nombre de Fantasía", property: "nombreFantasia", sortable: true },
    { title: "Rubro", property: "rubro", sortable: true },
    { title: "Telefono", property: "telefono", sortable: true },
  ];

  const menu = (item) => (
    <Menu>
      {!hasPermission(actions.ComerciosEditar) && (
        <Menu.Item
          key="1"
          icon={<FiSearch />}
          onClick={() => onClickEdit(item)}
        >
          Ver
        </Menu.Item>
      )}
      {hasPermission(actions.ComerciosEditar) && (
        <Menu.Item key="1" icon={<FiEdit />} onClick={() => onClickEdit(item)}>
          Editar
        </Menu.Item>
      )}
    </Menu>
  );

  const onClickAdd = () => {
    navigate("/comercios/nuevo");
  };

  const onClickEdit = (item) => {
    navigate(`/comercios/${item.id}`);
  };

  const buttons = [
    {
      title: "Agregar Comercio",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: hasPermission(actions.ListaNegraCrear),
    },
  ];

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          buttons={buttons}
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="comercios"
          columns={columns}
          menu={menu}
          url="/comercios"
          orderBy="nombreFantasia"
          orderDirection="ascending"
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default ComerciosListPage;

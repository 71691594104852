import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiInbox, FiSearch } from "react-icons/fi";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "../../components/ui/filters";
import Table from "../../components/ui/table";
import { TableContextProvider } from "../../store/table-context";

import SolicitudesPJEstado from "../../components/solicitudesPJ/solicitudesPJ-estado";

const SolicitudesPJListPage = () => {
  let navigate = useNavigate();

  const title = "Solicitudes Persona Juridica";
  const icon = <FiInbox />;
  const breadcrumb = [{ title: "Solicitudes Persona Juridica", url: "/solicitudesPJ" }];

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      type: "date",
      label: "Fecha Desde",
      name: "desde",
      half: true,
    },
    {
      type: "date",
      label: "Fecha Hasta",
      name: "hasta",
      half: true,
    },
    {
      type: "input",
      label: "Nombre Fantasia",
      name: "nombreFantasia",
    },
    {
      type: "input",
      label: "CUIT",
      name: "cuit",
    },
    {
      type: "select",
      label: "Estado",
      name: "estado",
      values: [
        { value: "1", text: "Pendiente" },
        { value: "2", text: "Aprobada" },
        { value: "3", text: "Rechazada" },
        { value: "4", text: "Validación Manual" },
        { value: "5", text: "Pendiente credenciales" },
        { value: "6", text: "Error en alta" },
      ],
      onChange: (value, form) => {
        form.setFieldsValue({ motivoRechazo: '' });
        let nFilters = [...filters];
        nFilters[6].hidden = value !== "3";
        setFilters(nFilters);
      }
    },
    {
      type: "select",
      label: "Motivo de Rechazo",
      name: "motivoRechazo",
      hidden: true,
      values: [
        { value: "1", text: "Documento No Encontrado" },
        { value: "2", text: "Pdf No Encontrado" },
        { value: "3", text: "Intentos Excedidos" },
        { value: "4", text: "Persona No Encontrada" },
        { value: "5", text: "Persona Fallecida" },
        { value: "6", text: "Ejemplar No Valido" },
        { value: "7", text: "Prueba Vida Intentos Excedidos" },
        { value: "8", text: "Rechazo Matriz De Riesgo" },
        { value: "9", text: "Solicitud Previa Aprobada" },
      ],
    }
  ]);

  const columns = [
    { title: "Fecha", property: "fecha", sortable: true },
    { title: "Hora", property: "hora", sortable: false },
    { title: "Nombre Fantasia", property: "nombreFantasia", sortable: false },
    { title: "CUIT", property: "cuit", sortable: false },
    { title: "Email", property: "email", sortable: false },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <SolicitudesPJEstado estado={item.estado} title={item.motivoRechazo} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      <Menu.Item key="1" icon={<FiSearch />} onClick={() => onClickView(item)}>
        Ver
      </Menu.Item>
    </Menu>
  );

  const onClickView = (item) => {
    navigate(`/solicitudesPJ/${item.id}`);
  };

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          showFilters
          isFilter={isFilter}
        />
        <Filters fields={filters} />
        <Table
          id="table-solicitudesPJ"
          columns={columns}
          menu={menu}
          url="/solicitudesPJ"
          orderBy="fecha"
          orderDirection="descending"
          setIsFilter={setIsFilter}
        />
      </Card>
    </TableContextProvider>
  );
};

export default SolicitudesPJListPage;
